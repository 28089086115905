import { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import {
  parseHostToTenantAlias,
  TenantAlias,
} from '@/shared/tenants-alias-map';

// Careful, this hook will return undefined on server and on the first client render
export const useTenantAlias = (): TenantAlias => {
  const params = useParams();
  const [tenantAlias, setTenantAlias] = useState<TenantAlias>(
    params.tenantAlias as TenantAlias,
  );

  useEffect(() => {
    if (tenantAlias !== undefined) {
      return;
    }

    // get tenantAlias on client side to handle pages outside [tenantAlias] route
    const tenantAliasFromHostname = parseHostToTenantAlias(
      window.location.hostname,
      'agentHosts',
    );

    if (tenantAliasFromHostname) {
      setTenantAlias(tenantAliasFromHostname);
    } else {
      throw new Error('Tenant alias not found');
    }
  }, [params.tenantAlias, tenantAlias]);

  return tenantAlias;
};
