import { Link as MuiLink, LinkProps } from '@mui/material';
import { getSPAConfigFromTenantAlias } from '@/src/config/SPAConfig';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
type LogoAsSvgProps = {
  height: LinkProps['height'];
  variant: 'white' | 'default';
};
export const LinkedLogoAsSvg = ({
  height,
  variant
}: LogoAsSvgProps) => {
  const tenantAlias = useTenantAlias();
  const spaConfig = getSPAConfigFromTenantAlias(tenantAlias);
  return <MuiLink href="/" title="Go to homepage" height={height} sx={{
    display: 'flex',
    width: 'fit-content',
    mx: 'auto'
  }} data-sentry-element="MuiLink" data-sentry-component="LinkedLogoAsSvg" data-sentry-source-file="LinkedLogoAsSvg.tsx">
      {variant === 'white' ? spaConfig.logoWhite : spaConfig.logo}
    </MuiLink>;
};