'use client';

import { memo, useState } from 'react';
import { usePathname } from 'next/navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Link, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Stack, SxProps, Typography } from '@mui/material';
import { isNonEmptyArray } from '@/shared/types/util/TypeGuards';
import { SidebarLink } from '@/src/components/Layouts/AppLayout/Sidebar/components/sidebarLinks';
import { SIZE_LARGE } from '../SidebarDesktopContainer';
type SidebarNavItemInternalProps = SidebarLink & {
  onClick?: () => void;
  isSidebarSmallSize?: boolean;
  listItemButtonSx?: SxProps;
};
const SidebarNavItemInternal = ({
  title,
  href,
  isBadgeVisible = false,
  Icon,
  subLinks,
  onClick,
  isSidebarSmallSize,
  listItemButtonSx
}: SidebarNavItemInternalProps) => {
  const pathname = usePathname();
  const isActive = pathname === href;
  const hasSubLinks = isNonEmptyArray(subLinks);
  const isSubLinkActive = subLinks?.some(({
    href
  }) => href === pathname);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(isSubLinkActive);
  const link = <ListItem disablePadding sx={{
    minWidth: SIZE_LARGE
  }}>
      <ListItemButton component={Link} href={href} onClick={onClick} sx={{
      mb: 1.5,
      py: 1.5,
      pl: 2.75,
      gap: 2.75,
      bgcolor: isActive ? 'primary.main' : 'transparent',
      '&:hover': {
        bgcolor: 'primary.dark'
      },
      ...listItemButtonSx
    }}>
        <ListItemIcon sx={{
        minWidth: 24
      }}>
          <Badge invisible={!isBadgeVisible} variant="dot" sx={{
          '.MuiBadge-badge': {
            bgcolor: 'warning.dark'
          }
        }}>
            <SvgIcon sx={{
            color: 'primary.contrastText'
          }} component={Icon} />
          </Badge>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{
        variant: 'bodyLarge',
        fontWeight: 500,
        color: 'primary.contrastText'
      }} primary={title} />
      </ListItemButton>
    </ListItem>;
  if (hasSubLinks) {
    return <Accordion disableGutters expanded={isAccordionExpanded} onChange={(_event, isExpanded) => {
      setIsAccordionExpanded(isExpanded);
    }} sx={{
      border: '0',
      backgroundColor: 'inherit',
      mb: 1.5
    }}>
        <AccordionSummary sx={{
        pl: 2.75,
        pr: isSidebarSmallSize ? 0 : 2,
        py: 1.5,
        mb: isSidebarSmallSize ? 1.5 : 0,
        justifyContent: 'flex-start',
        '&:hover': {
          bgcolor: 'primary.dark'
        },
        '.MuiAccordionSummary-content': {
          m: 0,
          flexGrow: isSidebarSmallSize ? 0 : 1
        }
      }} expandIcon={<ExpandMoreIcon sx={{
        color: 'primary.contrastText',
        fontSize: isSidebarSmallSize ? 16 : 24
      }} />}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <SvgIcon sx={{
            color: 'primary.contrastText'
          }} component={Icon} />
            {isSidebarSmallSize ? null : <Typography color="primary.contrastText">{title}</Typography>}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{
        p: 0
      }}>
          {subLinks.map((subLink, index) => <SidebarNavItemInternal key={index} {...subLink} listItemButtonSx={isSidebarSmallSize ? {
          py: 0.5
        } : {
          pl: 5,
          py: 0.5,
          mb: 0.5
        }} />)}
        </AccordionDetails>
      </Accordion>;
  }
  return link;
};
export const SidebarNavItem = memo(SidebarNavItemInternal);