import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { Box, Container } from '@mui/material';
import navbarBg from '@/src/components/Layouts/AppLayout/Navbar/assets/navbar-bg.svg';
import { SidebarMobileContainer } from '@/src/components/Layouts/AppLayout/Sidebar/SidebarMobileContainer';
import { LinkedLogoAsSvg } from '../../../Logo/LinkedLogoAsSvg';
const NavbarCallToActionButtons = dynamic(() => import('./NavbarCallToActionButtons').then(mod => mod.NavbarCallToActionButtons));
export const StickyNavbar = ({
  children,
  hasBackground
}: PropsWithChildren<{
  hasBackground: boolean;
}>) => {
  const bg = {
    backgroundImage: `url(${navbarBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom left',
    backgroundRepeat: 'no-repeat'
  };
  return <Box component="header" sx={theme => ({
    // bgcolor: 'common.white',
    background: `linear-gradient(90deg, ${theme.palette.common.black} 80%, ${theme.palette.primary.main})`,
    py: [0.5, 0.5, 1.5],
    // Doesn't match anything from the theme
    boxShadow: '0 1px 4px 0 #15223214',
    position: 'sticky',
    top: 0,
    zIndex: 'drawer',
    ...(hasBackground ? bg : {})
  })} data-sentry-element="Box" data-sentry-component="StickyNavbar" data-sentry-source-file="StickyNavbar.tsx">
      <Container maxWidth={false} sx={{
      px: [1, 1, 2],
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: [1, 1, 4]
    }} data-sentry-element="Container" data-sentry-source-file="StickyNavbar.tsx">
        <SidebarMobileContainer data-sentry-element="SidebarMobileContainer" data-sentry-source-file="StickyNavbar.tsx" />
        <Box sx={{
        px: 1,
        py: 0.5
      }} data-sentry-element="Box" data-sentry-source-file="StickyNavbar.tsx">
          <LinkedLogoAsSvg height={[40, 40, 45]} variant="white" data-sentry-element="LinkedLogoAsSvg" data-sentry-source-file="StickyNavbar.tsx" />
        </Box>
        {children}
        <NavbarCallToActionButtons data-sentry-element="NavbarCallToActionButtons" data-sentry-source-file="StickyNavbar.tsx" />
      </Container>
    </Box>;
};