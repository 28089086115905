import {
  getRestApiURLforTenant,
  getSPAApiURLForTenant,
  getWebApiURLForTenant,
} from '@qb/frontend/utils/hosts/apiHost';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { LogFrontendPageLoadPayload } from '@/shared/types/controllers/AnalyticsControllerTypes';
import { AppDisplayPayload } from '@/shared/types/controllers/AppControllerTypes';
import {
  ForgotPasswordPayload,
  LoginPayload,
  LoginResponse,
} from '@/shared/types/controllers/AuthControllerTypes';
import {
  CompanyLogoResponse,
  CompanySettingsMarketplaceDataResponse,
  CompanySettingsIntegrationsDataResponse,
  CompanyAllUsersAsOptions,
  CompanySettingsSupplierDataResponse,
  GetSupportedManufacturersSettingsListResponse,
  GetSupportedManufacturerDataRequestParams,
  GetSupportedManufacturerDataResponse,
} from '@/shared/types/controllers/CompanyControllerTypes';
import {
  OrderDisplayDataResponse,
  SetTaxAndShippingOverridePayload,
  OrderUpdateShippingMethodPayload,
} from '@/shared/types/controllers/OrderControllerTypes';
import {
  OrderItemUpdateInstanceBuyerPayload,
  OrderItemUpdateInstanceSupplierPayload,
} from '@/shared/types/controllers/OrderItemControllerTypes';
import {
  PartAvailabilityForSupplierResponse,
  PartPublicDataResponse,
} from '@/shared/types/controllers/PartControllerTypes';
import {
  PartRequestCreatePartRequestPayload,
  PartRequestUpdatePriceAndQtyPayload,
  PartRequestUpdateRankingPayload,
} from '@/shared/types/controllers/PartRequestControllerTypes';
import {
  QuoteDisplayDataResponse,
  QuoteListBuyerDataPayload,
  QuoteListBuyerDataResponse,
  QuoteListSupplierDataPayload,
  QuoteListSupplierDataResponse,
  QuoteNoBidPayload,
  QuoteRecentBuyerQuotesPayload,
  QuoteRecentBuyerQuotesResponse,
  QuoteRecentContactQuotesPayload,
  QuoteRecentContactQuotesResponse,
  QuoteSubscribePayload,
  QuoteUnsubscribePayload,
  QuoteUpdateAssigneePayload,
  QuoteUpdateNotesSupplierPayload,
  QuoteUpdateQuoteNotePayload,
  QuoteUpdateValidityDurationPayload,
} from '@/shared/types/controllers/QuoteControllerTypes';
import {
  QuoteFeeCreateQuoteFeePayload,
  QuoteFeeCreateQuoteFeeResponse,
  QuoteFeeUpdateQuoteFeePayload,
  QuoteFeeUpdateQuoteFeeResponse,
} from '@/shared/types/controllers/QuoteFeeControllerTypes';
import { SalesTerritorySetSalesTerritoryBatchPayload } from '@/shared/types/controllers/SalesTerritoryControllerTypes';
import {
  ContactAutocompletePayload,
  ContactAutocompleteResponse,
  SearchPartAutocompleteResponse,
} from '@/shared/types/controllers/SearchControllerTypes';
import {
  SupplierPriceAddPriceForPartPayload,
  SupplierPriceAddPriceForPartResponse,
  SupplierPriceManufacturersListResponse,
  SupplierPriceSearchOwnPartsResponse,
  SupplierPriceUpdateSupplierPricePayload,
  SupplierPriceUploadPartsPricesFilePayload,
} from '@/shared/types/controllers/SupplierPriceControllerTypes';
import {
  ChangePasswordPayload,
  DemoteFromAdminPayload,
  DisableUserPayload,
  EnableUserPayload,
  GetProfileImageResponse,
  PromoteToAdminPayload,
  SettingsNotificationsDataResponse,
  UpdateMuteSMSRequest,
} from '@/shared/types/controllers/UserControllerTypes';
import { UserInviteTokenFindOneUserInviteTokenResponse } from '@/shared/types/controllers/UserInviteTokenControllerTypes';
import { SignupPayload } from '@/shared/types/controllers/spa/SPAAuthControllerTypes';
import {
  GetCertificateListPayload,
  GetCertificateListResponse,
  SetCertificateApprovalPayload,
} from '@/shared/types/controllers/spa/SPACertificateControllerTypes';
import {
  CompanyUpdateIsInitialConversationNotifyPayload,
  CompanyUpdateIsInitialPONotifyPayload,
  CompanyUpdateIsInitialQuoteNotifyPayload,
  CompanySettingsUsersDataResponse,
  CompanyInfoResponse,
} from '@/shared/types/controllers/spa/SPACompanyControllerTypes';
import {
  ContactCreateForCompanyPayload,
  ContactCreateForCompanyResponse,
  ContactCreatePayload,
  ContactCreateResponse,
  ContactFindOneByEmailPayload,
  ContactFindOneByEmailResponse,
  ContactFindOneResponse,
  ContactListPayload,
  ContactListResponse,
} from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import {
  ConversationDisplayDataResponse,
  ConversationHasUnreadConversationResponse,
} from '@/shared/types/controllers/spa/SPAConversationControllerTypes';
import {
  CouponCodeCreateCouponCodePayload,
  CouponCodeGetResponse,
  CouponCodeListCouponCodesResponse,
  CouponCodeUpdateCouponCodePayload,
} from '@/shared/types/controllers/spa/SPACouponCodeControllerTypes';
import { SupplierDashboardDataResponse } from '@/shared/types/controllers/spa/SPADashboardControllerTypes';
import { GetErpShippingMethodsResponse } from '@/shared/types/controllers/spa/SPAIntegrationControllerTypes';
import { NavbarGetDataResponse } from '@/shared/types/controllers/spa/SPANavbarControllerTypes';
import { NotificationPolicyUpsertPayload } from '@/shared/types/controllers/spa/SPANotificationPolicyControllerTypes';
import {
  OrderCustomerDetailsResponse,
  OrderDenyPayload,
  OrderGetIntegrationExceptionsResponse,
  OrderListPayload,
  OrderListResponse,
  OrderPartialShipPayload,
  OrderRecentBuyerOrdersPayload,
  OrderRecentBuyerOrdersResponse,
  OrderUpdateSupplierNotesPayload,
  SetManualSupplierIntegrationPayload,
} from '@/shared/types/controllers/spa/SPAOrderControllerTypes';
import {
  GetPaymentTermsDashboardPayload,
  GetPaymentTermsDashboardResponse,
} from '@/shared/types/controllers/spa/SPAPaymentTermControllerTypes';
import {
  GetProductLinesByManufacturerRequest,
  GetProductLinesByManufacturerResponse,
} from '@/shared/types/controllers/spa/SPAProductLineControllerTypes';
import { CreateForContactPayload } from '@/shared/types/controllers/spa/SPAQuoteControllerTypes';
import {
  CompanyAutocompletePayload,
  CompanyAutocompleteResponse,
} from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import {
  GetSupplierPriceDashboardPayload,
  GetSupplierPriceDashboardResponse,
  UploadPartsPricesFilePayload,
} from '@/shared/types/controllers/spa/SPASupplierPriceControllerTypes';
import { ListSupplierPriceSetsResponse } from '@/shared/types/controllers/spa/SPASupplierPriceSetControllerTypes';
import {
  GetSupportedManufacturerListResponse,
  GetSupportedManufacturerListPayload,
  CreateSupportedManufacturerPayload,
  GetManufacturersByNamePayload,
  GetManufacturersByNameResponse,
  GetManufacturerStats,
} from '@/shared/types/controllers/spa/SPASupportedManufacturerControllerTypes';
import {
  GetSupportedPartCategoryListResponse,
  GetSupportedPartCategoryListPayload,
  CreateSupportedPartCategoryBatchPayload,
  CreateSupportedPartCategoryPayload,
  CreateSupportedPartCategoryResponse,
} from '@/shared/types/controllers/spa/SPASupportedPartCategoryControllerTypes';
import {
  CreateSupportedProductLineBatchPayload,
  CreateSupportedProductLineResponse,
  CreateSupportedProductLineRequest,
} from '@/shared/types/controllers/spa/SPASupportedProductLineControllerTypes';
import {
  CurrentUserInfoResponse,
  UserUpdateUserPayload,
  UserUpdateUserResponse,
  UserUploadProfileImagePayload,
} from '@/shared/types/controllers/spa/SPAUserControllerTypes';
import {
  deleteRequestPromise,
  getRequestPromise,
  postRequestPromise,
  uploadRequestPromise,
} from './RequestPromise';
import {
  getSocketRequestPromise,
  postSocketRequestPromise,
} from './SocketRequestPromise';

export const spaQueries = {
  app: {
    update: (tenantAlias: TenantAlias, appID: number) => ({
      mutationFn: (payload: AppDisplayPayload) =>
        postRequestPromise<void, AppDisplayPayload>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/app/${appID}`,
          payload,
        ),
    }),
  },
  analytics: {
    logPageLoad: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: LogFrontendPageLoadPayload) =>
        postRequestPromise<void, LogFrontendPageLoadPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/analytics/logPageLoad`,
          payload,
        ),
    }),
  },
  auth: {
    login: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: LoginPayload) =>
        postRequestPromise<LoginResponse, LoginPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/login`,
          payload,
        ),
    }),
    logout: (tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/logout`,
          undefined,
          'Unable to logout',
        ),
    }),
    signup: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: SignupPayload) =>
        postRequestPromise<void, SignupPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/signup`,
          payload,
        ),
    }),
    forgotPassword: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: ForgotPasswordPayload) =>
        postRequestPromise<void, ForgotPasswordPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/forgotPassword`,
          payload,
        ),
    }),
  },
  certificate: {
    queryKey: ['certificate'],
    certificateList: (
      tenantAlias: TenantAlias,
      queryParams: GetCertificateListPayload,
    ) => ({
      queryKey: ['certificateList', queryParams],
      queryFn: () =>
        getRequestPromise<GetCertificateListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/certificate`,
          { params: queryParams },
        ),
    }),
    certificateApproval: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: SetCertificateApprovalPayload) =>
        postRequestPromise<void, SetCertificateApprovalPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/certificate/${payload.certificateID}/setApproval`,
          payload,
        ),
    }),
  },
  company: {
    queryKey: ['company'],
    companyInfo: (tenantAlias: TenantAlias, companyID: number) => ({
      queryKey: ['company', 'companyInfo', companyID],
      queryFn: () =>
        getRequestPromise<CompanyInfoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/${companyID}/companyInfo`,
        ),
    }),
    logo: (tenantAlias: TenantAlias, companyID: number) => ({
      queryKey: ['company', 'logo', companyID],
      queryFn: () =>
        getRequestPromise<CompanyLogoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/${companyID}/logo`,
        ).then((data) => data.url),
    }),
    allUsersAsOptions: (tenantAlias: TenantAlias) => ({
      queryKey: ['company', 'allUsersAsOptions'],
      queryFn: () =>
        getRequestPromise<CompanyAllUsersAsOptions>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/allUsersAsOptions`,
        ),
    }),
    updateIsInitialQuoteNotify: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CompanyUpdateIsInitialQuoteNotifyPayload) =>
        postRequestPromise<void, CompanyUpdateIsInitialQuoteNotifyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateIsInitialQuoteNotify`,
          payload,
        ),
    }),
    updateIsInitialPONotify: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CompanyUpdateIsInitialPONotifyPayload) =>
        postRequestPromise<void, CompanyUpdateIsInitialPONotifyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateIsInitialPONotify`,
          payload,
        ),
    }),
    updateIsInitialConversationNotify: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CompanyUpdateIsInitialConversationNotifyPayload) =>
        postRequestPromise<
          void,
          CompanyUpdateIsInitialConversationNotifyPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateIsInitialConversationNotify`,
          payload,
        ),
    }),
    supportedManufacturerDataOLD: (
      tenantAlias: TenantAlias,
      params: GetSupportedManufacturerDataRequestParams,
    ) => ({
      queryKey: ['company', 'settings', 'supportedManufacturerData', params],
      queryFn: () =>
        getRequestPromise<GetSupportedManufacturerDataResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/settings/supportedManufacturerData`,
          { params },
        ),
    }),
    supportedManufacturersListOLD: (tenantAlias: TenantAlias) => ({
      queryKey: ['company', 'supportedManufacturersList'],
      queryFn: () =>
        getRequestPromise<GetSupportedManufacturersSettingsListResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/settings/supportedManufacturersList`,
        ),
    }),
    getSupportedManufacturers: (
      tenantAlias: TenantAlias,
      params: GetSupportedManufacturerListPayload,
    ) => ({
      queryKey: ['company', 'getSupportedManufacturers', params],
      queryFn: () =>
        getRequestPromise<GetSupportedManufacturerListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer`,
          { params },
        ),
    }),
    getSupportedManufacturerByName: (
      tenantAlias: TenantAlias,
      params: GetManufacturersByNamePayload,
    ) => ({
      queryKey: ['company', 'getSupportedManufacturerByName', params],
      queryFn: () =>
        getRequestPromise<GetManufacturersByNameResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer/byName`,
          { params },
        ),
    }),
    getManufacturerStats: (
      tenantAlias: TenantAlias,
      manufacturerID: number,
    ) => ({
      queryKey: ['company', 'getManufacturerStats', manufacturerID],
      queryFn: () =>
        getRequestPromise<GetManufacturerStats>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/stats/${manufacturerID}`,
        ),
    }),
    createSupportedManufacturer: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CreateSupportedManufacturerPayload) =>
        postRequestPromise<void, CreateSupportedManufacturerPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer`,
          payload,
        ),
    }),
    deleteSupportedManufacturer: (tenantAlias: TenantAlias) => ({
      mutationFn: (manufacturerID: number) =>
        deleteRequestPromise<void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer/${manufacturerID}`,
        ),
    }),
    // Supported Part Category
    getSupportedPartCategory: (
      tenantAlias: TenantAlias,
      params: GetSupportedPartCategoryListPayload,
    ) => ({
      queryKey: ['company', 'getSupportedPartCategory', params],
      queryFn: () =>
        getRequestPromise<GetSupportedPartCategoryListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory`,
          { params },
        ),
    }),
    createSupportedPartCategory: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CreateSupportedPartCategoryPayload) =>
        postRequestPromise<
          CreateSupportedPartCategoryResponse,
          CreateSupportedPartCategoryPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory`,
          payload,
        ),
    }),
    createSupportedPartCategoryBatch: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CreateSupportedPartCategoryBatchPayload) =>
        postRequestPromise<void, CreateSupportedPartCategoryBatchPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory/batch`,
          payload,
        ),
    }),
    deleteSupportedPartCategory: (tenantAlias: TenantAlias) => ({
      mutationFn: (supportedPartCategoryID: number) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory/${supportedPartCategoryID}`,
        ),
    }),
    // Product Line
    getProductLinesByManufacturer: (
      tenantAlias: TenantAlias,
      params: GetProductLinesByManufacturerRequest,
    ) => ({
      queryKey: ['company', 'getProductLinesByManufacturer', params],
      queryFn: () =>
        getRequestPromise<GetProductLinesByManufacturerResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/productLine`,
          { params },
        ),
    }),
    createSupportedProductLine: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CreateSupportedProductLineRequest) =>
        postRequestPromise<
          CreateSupportedProductLineResponse,
          CreateSupportedProductLineRequest
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedProductLine`,
          payload,
        ),
    }),
    createSupportedProductLineBatch: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CreateSupportedProductLineBatchPayload) =>
        postRequestPromise<void, CreateSupportedProductLineBatchPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedProductLine/batch`,
          payload,
        ),
    }),
    deleteSupportedProductLine: (tenantAlias: TenantAlias) => ({
      mutationFn: (productLineID: number) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedProductLine/${productLineID}`,
        ),
    }),
    settings: {
      usersData: (tenantAlias: TenantAlias) => ({
        queryKey: ['company', 'settings', 'usersData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsUsersDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/usersData`,
          ),
      }),
      marketplaceData: (tenantAlias: TenantAlias) => ({
        queryKey: ['company', 'settings', 'marketplaceData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsMarketplaceDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/marketplaceData`,
          ),
      }),
      supplierData: (tenantAlias: TenantAlias) => ({
        queryKey: ['company', 'settings', 'supplierData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsSupplierDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/supplierData`,
          ),
      }),
      integrationsData: (tenantAlias: TenantAlias) => ({
        queryKey: ['company', 'settings', 'integrationsData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsIntegrationsDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/integrationsData`,
          ),
      }),
    },
  },
  contact: {
    findOne: (tenantAlias: TenantAlias, contactID: number) => ({
      queryKey: ['contact', contactID],
      queryFn: () =>
        getRequestPromise<ContactFindOneResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/${contactID}`,
        ),
    }),
    findOneByEmail: (
      tenantAlias: TenantAlias,
      params: ContactFindOneByEmailPayload,
    ) => ({
      queryKey: ['contact', 'findOneByEmail', params],
      queryFn: () =>
        getRequestPromise<ContactFindOneByEmailResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/findOneByEmail`,
          { params },
        ),
    }),
    listContacts: (tenantAlias: TenantAlias, params?: ContactListPayload) => ({
      queryKey: ['contact', 'listContacts', params],
      queryFn: () =>
        getRequestPromise<ContactListResponse>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/contact`,
          { params },
        ),
    }),
    createContact: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: ContactCreatePayload) =>
        postRequestPromise<ContactCreateResponse, ContactCreatePayload>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/contact`,
          payload,
        ),
    }),
    createForCompany: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: ContactCreateForCompanyPayload) =>
        postRequestPromise<
          ContactCreateForCompanyResponse,
          ContactCreateForCompanyPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/createForCompany`,
          payload,
        ),
    }),
  },
  conversation: {
    hasUnreadConversation: (tenantAlias: TenantAlias) => ({
      queryKey: ['conversation', 'hasUnreadConversation'],
      queryFn: () =>
        getSocketRequestPromise<
          void,
          ConversationHasUnreadConversationResponse
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/hasUnreadConversation`,
        ).then((data) => data.hasUnreadConversation),
    }),
    displayData: (conversationID: number, tenantAlias: TenantAlias) => ({
      queryKey: ['conversation', 'displayData', conversationID],
      queryFn: () =>
        getSocketRequestPromise<void, ConversationDisplayDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/${conversationID}/displayData`,
        ).then((data) => data?.conversation),
      enabled: Boolean(conversationID),
    }),
    typing: (conversationID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postSocketRequestPromise(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/${conversationID}/typing`,
        ),
    }),
    join: (conversationID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () => {
        return postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/${conversationID}/join`,
          undefined,
        );
      },
    }),
  },
  couponCode: {
    queryKey: ['couponCode'],
    get: (couponCodeID: number, tenantAlias: TenantAlias) => ({
      queryKey: ['couponCode', 'get', couponCodeID],
      queryFn: () =>
        getRequestPromise<CouponCodeGetResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode/${couponCodeID}`,
        ),
    }),
    create: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CouponCodeCreateCouponCodePayload) =>
        postRequestPromise<void, CouponCodeCreateCouponCodePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode`,
          payload,
        ),
    }),
    update: (tenantAlias: TenantAlias) => ({
      mutationFn: ({
        couponCodeID,
        payload,
      }: {
        couponCodeID: number;
        payload: CouponCodeUpdateCouponCodePayload;
      }) =>
        postRequestPromise<void, CouponCodeUpdateCouponCodePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode/${couponCodeID}`,
          payload,
        ),
    }),
    list: (tenantAlias: TenantAlias) => ({
      queryKey: ['couponCode', 'list'],
      queryFn: () =>
        getRequestPromise<CouponCodeListCouponCodesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode`,
        ),
    }),
  },
  dashboard: {
    queryKey: ['dashboard'],
    supplierData: (tenantAlias: TenantAlias) => ({
      queryKey: ['dashboard', 'supplierData', tenantAlias],
      queryFn: () =>
        getRequestPromise<SupplierDashboardDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierDashboardData`,
        ),
    }),
  },
  integration: {
    erpShippingMethods: (tenantAlias: TenantAlias) => ({
      queryKey: ['integration', 'erpShippingMethods'],
      queryFn: () =>
        getRequestPromise<GetErpShippingMethodsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/erpShippingMethods`,
        ),
    }),
  },
  navbar: {
    queryKey: ['navbar'],
    displayData: (tenantAlias: TenantAlias) => ({
      queryKey: ['navbar', 'index'],
      queryFn: () =>
        getRequestPromise<NavbarGetDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/navbar/data`,
        ),
    }),
  },
  notificationPolicy: {
    upsert: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: NotificationPolicyUpsertPayload) =>
        postRequestPromise<void, NotificationPolicyUpsertPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/notificationPolicy/upsert`,
          payload,
          'Unable to modify notification settings at this time. Please try again later',
        ),
    }),
  },
  order: {
    queryKey: ['order'],
    received: (queryParams: OrderListPayload, tenantAlias: TenantAlias) => ({
      queryKey: ['order', 'received', queryParams],
      queryFn: () =>
        getRequestPromise<OrderListResponse>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/order`,
          { params: queryParams },
        ),
    }),
    displayData: (orderID: number, tenantAlias: TenantAlias) => ({
      queryKey: ['order', orderID, 'displayData'],
      queryFn: () =>
        getRequestPromise<OrderDisplayDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}`,
        ),
      enabled: Boolean(orderID),
    }),
    setManualSupplierIntegration: (
      orderID: number,
      tenantAlias: TenantAlias,
    ) => ({
      mutationFn: (payload: SetManualSupplierIntegrationPayload) =>
        postRequestPromise<void, SetManualSupplierIntegrationPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/manualSupplierIntegration`,
          payload,
        ),
    }),
    customerDetails: (orderID: number, tenantAlias: TenantAlias) => ({
      queryKey: ['order', orderID, 'customerDetails'],
      queryFn: () =>
        getRequestPromise<OrderCustomerDetailsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/customerDetails`,
        ),
    }),
    recentBuyerOrders: (
      payload: OrderRecentBuyerOrdersPayload,
      tenantAlias: TenantAlias,
    ) => ({
      queryKey: ['order', 'recentBuyerOrders', payload],
      queryFn: () =>
        getRequestPromise<OrderRecentBuyerOrdersResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/recentBuyerOrders`,
          { params: payload },
        ),
    }),
    accept: (orderID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/accept`,
          undefined,
          'There was an error updating order status, please try again',
        ),
    }),
    deny: (orderID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: OrderDenyPayload) =>
        postRequestPromise<void, OrderDenyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/deny`,
          payload,
          'There was an error updating order status, please try again',
        ),
    }),
    partialShip: (orderID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: OrderPartialShipPayload) =>
        postRequestPromise<void, OrderPartialShipPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/partialShip`,
          payload,
          'There was an error updating order status, please try again',
        ),
    }),
    updateSupplierNotes: (orderID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: OrderUpdateSupplierNotesPayload) =>
        postRequestPromise<void, OrderUpdateSupplierNotesPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/updateSupplierNotes`,
          payload,
          'There was an error updating the note. Please try again later',
        ),
    }),
    validateIntegrationException: (tenantAlias: TenantAlias) => ({
      mutationFn: ({
        orderID,
        integrationExceptionID,
      }: {
        orderID: number;
        integrationExceptionID: number;
      }) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/validateAndResolveIntegrationException/${integrationExceptionID}`,
        ),
    }),
    integrationExceptions: (orderID: number, tenantAlias: TenantAlias) => ({
      queryKey: ['order', orderID, 'integrationExceptions'],
      queryFn: () =>
        getRequestPromise<OrderGetIntegrationExceptionsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/integrationExceptions`,
        ),
    }),
    createSupplierErpOrder: (orderID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/supplierErpOrder`,
        ),
    }),
    overrideIntegrationException: (tenantAlias: TenantAlias) => ({
      mutationFn: ({
        orderID,
        integrationExceptionID,
      }: {
        orderID: number;
        integrationExceptionID: number;
      }) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/overrideIntegrationException/${integrationExceptionID}`,
        ),
    }),
    applyIntegrationPaymentTerms: (
      orderID: number,
      integrationExceptionID: number,
      tenantAlias: TenantAlias,
    ) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/applyIntegrationPaymentTerms/${integrationExceptionID}`,
        ),
    }),
    updateShippingMethod: (
      orderID: number,
      integrationExceptionID: number,
      tenantAlias: TenantAlias,
    ) => ({
      mutationFn: (payload: OrderUpdateShippingMethodPayload) =>
        postRequestPromise<void, OrderUpdateShippingMethodPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/updateShippingMethod/${integrationExceptionID}`,
          payload,
        ),
    }),
    setTaxAndShippingOverride: (orderID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: SetTaxAndShippingOverridePayload) =>
        postRequestPromise<void, SetTaxAndShippingOverridePayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/setTaxAndShippingOverride`,
          payload,
          'There was an error updating the order. Please try again later',
        ),
    }),
  },
  orderItem: {
    queryKey: ['orderItem'],
    cancelItem: (orderItemID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        deleteRequestPromise(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/orderItem/${orderItemID}/cancelItem`,
          {},
          'There was an error canceling order item, please try again',
        ),
    }),
    updateInstanceBuyer: (orderItemID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: OrderItemUpdateInstanceBuyerPayload) =>
        postRequestPromise<void, OrderItemUpdateInstanceBuyerPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/orderItem/${orderItemID}/updateInstanceBuyer`,
          payload,
          'There was an error updating order details, please try again',
        ),
    }),
    updateInstanceSupplier: (
      orderItemID: number,
      tenantAlias: TenantAlias,
    ) => ({
      mutationFn: (payload: OrderItemUpdateInstanceSupplierPayload) =>
        postRequestPromise<void, OrderItemUpdateInstanceSupplierPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/orderItem/${orderItemID}/updateInstanceSupplier`,
          payload,
          'There was an error updating order details, please try again',
        ),
    }),
  },
  part: {
    publicData: (partID: number, tenantAlias: TenantAlias) => ({
      queryKey: ['part', 'publicData', partID],
      queryFn: () =>
        getRequestPromise<PartPublicDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/part/${partID}/publicData`,
        ),
    }),
    availabilityForSupplier: (
      partID: number,
      tenantAlias: TenantAlias,
      supplierID?: number,
    ) => ({
      queryKey: ['part', 'availabilityForSupplier', partID],
      queryFn: () =>
        getRequestPromise<PartAvailabilityForSupplierResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/part/${partID}/availabilityForSupplier`,
          { params: { supplierID } },
        ),
    }),
  },
  partRequest: {
    queryKey: ['partRequest'],
    create: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: PartRequestCreatePartRequestPayload) =>
        postRequestPromise<void, PartRequestCreatePartRequestPayload>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest`,
          payload,
        ),
    }),
    remove: (tenantAlias: TenantAlias) => ({
      mutationFn: (partRequestID: number) =>
        deleteRequestPromise<void>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}`,
        ),
    }),
    updateRanking: (tenantAlias: TenantAlias) => ({
      mutationFn: ({
        partRequestID,
        payload,
      }: {
        partRequestID: number;
        payload: PartRequestUpdateRankingPayload;
      }) =>
        postRequestPromise<void, PartRequestUpdateRankingPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}/updateRanking`,
          payload,
        ),
    }),
    updatePriceAndQty: (partRequestID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: PartRequestUpdatePriceAndQtyPayload) =>
        postRequestPromise<void, PartRequestUpdatePriceAndQtyPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}/updatePriceAndQty`,
          payload,
          'Unable to update quote. Please try again later',
        ),
    }),
  },
  paymentTerm: {
    queryKey: ['paymentTerm'],
    dashboard: (
      tenantAlias: TenantAlias,
      params: GetPaymentTermsDashboardPayload,
    ) => ({
      queryKey: ['paymentTerm', 'dashboard', params],
      queryFn: () =>
        getRequestPromise<GetPaymentTermsDashboardResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/paymentTerm/dashboard`,
          { params },
        ),
    }),
  },
  quote: {
    queryKey: ['quote'],
    displayData: (
      quoteID: number,
      tenantAlias: TenantAlias,
      quoteShareToken?: string,
    ) => ({
      queryKey: ['quote', quoteID, 'displayData'],
      queryFn: () =>
        getRequestPromise<QuoteDisplayDataResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/displayData`,
          { params: { quoteShareToken } },
        ),
      enabled: Boolean(quoteID),
    }),
    subscribe: (
      tenantAlias: TenantAlias,
      quoteID: number,
      payload: QuoteSubscribePayload,
    ) => ({
      queryKey: ['quote', 'subscribe', quoteID],
      queryFn: () =>
        getSocketRequestPromise<QuoteSubscribePayload, void>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/subscribe`,
          payload,
        ),
    }),
    unsubscribe: (
      tenantAlias: TenantAlias,
      quoteID: number,
      payload: QuoteUnsubscribePayload,
    ) => ({
      queryKey: ['quote', 'unsubscribe', quoteID],
      queryFn: () =>
        getSocketRequestPromise<QuoteUnsubscribePayload, void>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/unsubscribe`,
          payload,
        ),
    }),
    noBid: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: QuoteNoBidPayload) =>
        postRequestPromise<void, QuoteNoBidPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/noBid`,
          payload,
        ),
    }),
    yesBid: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/yesBid`,
        ),
    }),
    submit: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/submit`,
        ),
    }),
    reopen: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/reopen`,
        ),
    }),
    updateQuoteNote: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: QuoteUpdateQuoteNotePayload) =>
        postRequestPromise<void, QuoteUpdateQuoteNotePayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateQuoteNote`,
          payload,
        ),
    }),
    updateNotesSupplier: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: QuoteUpdateNotesSupplierPayload) =>
        postRequestPromise<void, QuoteUpdateNotesSupplierPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateNotesSupplier`,
          payload,
        ),
    }),
    updateAssignee: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: QuoteUpdateAssigneePayload) =>
        postRequestPromise<void, QuoteUpdateAssigneePayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateAssignee`,
          payload,
        ),
    }),
    updateValidityDuration: (quoteID: number, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: QuoteUpdateValidityDurationPayload) =>
        postRequestPromise<void, QuoteUpdateValidityDurationPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateValidityDuration`,
          payload,
        ),
    }),
    listBuyerData: (
      tenantAlias: TenantAlias,
      params?: QuoteListBuyerDataPayload,
    ) => ({
      queryKey: ['quote', 'listBuyerData', params],
      queryFn: () =>
        getRequestPromise<QuoteListBuyerDataResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/listBuyerData`,
          { params },
        ),
    }),
    listSupplierData: (
      tenantAlias: TenantAlias,
      params?: QuoteListSupplierDataPayload,
    ) => ({
      queryKey: ['quote', 'listSupplierData', params],
      queryFn: () =>
        getRequestPromise<QuoteListSupplierDataResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/listSupplierData`,
          { params },
        ),
    }),
    recentBuyerQuotes: (
      payload: QuoteRecentBuyerQuotesPayload,
      tenantAlias: TenantAlias,
    ) => ({
      queryKey: ['quote', 'recentBuyerQuotes', payload],
      queryFn: () =>
        getRequestPromise<QuoteRecentBuyerQuotesResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/recentBuyerQuotes`,
          { params: payload },
        ),
    }),
    recentContactQuotes: (
      tenantAlias: TenantAlias,
      payload: QuoteRecentContactQuotesPayload,
    ) => ({
      queryKey: ['quote', 'recentContactQuotes', payload],
      queryFn: () =>
        getRequestPromise<QuoteRecentContactQuotesResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/recentContactQuotes`,
          { params: payload },
        ),
    }),
    createForContact: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: CreateForContactPayload) =>
        postRequestPromise<void, CreateForContactPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/createForContact`,
          payload,
        ),
    }),
  },
  quoteFee: {
    queryKey: ['quoteFee'],
    create: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: QuoteFeeCreateQuoteFeePayload) =>
        postRequestPromise<
          QuoteFeeCreateQuoteFeeResponse,
          QuoteFeeCreateQuoteFeePayload
        >(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quoteFee`,
          payload,
        ),
    }),
    update: (tenantAlias: TenantAlias) => ({
      mutationFn: ({
        quoteFeeID,
        payload,
      }: {
        quoteFeeID: number;
        payload: QuoteFeeUpdateQuoteFeePayload;
      }) =>
        postRequestPromise<
          QuoteFeeUpdateQuoteFeeResponse,
          QuoteFeeUpdateQuoteFeePayload
        >(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quoteFee/${quoteFeeID}`,
          payload,
        ),
    }),
    delete: (tenantAlias: TenantAlias) => ({
      mutationFn: (quoteFeeID: number) =>
        deleteRequestPromise<void>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quoteFee/${quoteFeeID}`,
        ),
    }),
  },
  salesTerritory: {
    queryKey: ['salesTerritory'],
    setSalesTerritoryBatch: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: SalesTerritorySetSalesTerritoryBatchPayload) =>
        postRequestPromise<void, SalesTerritorySetSalesTerritoryBatchPayload>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/salesTerritory/setSalesTerritoryBatch`,
          payload,
        ),
    }),
  },
  search: {
    queryKey: ['search'],
    partAutocomplete: (
      params: { query?: string },
      tenantAlias: TenantAlias,
    ) => ({
      queryKey: ['search', 'partAutocomplete', params],
      queryFn: () =>
        getRequestPromise<SearchPartAutocompleteResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/partAutocomplete`,
          { params },
        ),
    }),
    contactAutocomplete: (
      params: ContactAutocompletePayload,
      tenantAlias: TenantAlias,
    ) => ({
      queryKey: ['search', 'contactAutocomplete', params],
      queryFn: () =>
        getRequestPromise<ContactAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/contactAutocomplete`,
          { params },
        ),
    }),
    companyAutocomplete: (
      params: CompanyAutocompletePayload,
      tenantAlias: TenantAlias,
    ) => ({
      queryKey: ['search', 'companyAutocomplete', params],
      queryFn: () =>
        getRequestPromise<CompanyAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/companyAutocomplete`,
          { params },
        ),
    }),
  },
  supplierPrice: {
    queryKey: ['supplierPrice'],
    manufacturersList: (
      tenantAlias: TenantAlias,
      params: { search: string },
    ) => ({
      queryKey: ['supplierPrice', 'manufacturersList', params],
      queryFn: () =>
        getRequestPromise<SupplierPriceManufacturersListResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/manufacturersList`,
          { params },
        ),
    }),
    manufacturerPriceTemplate: (tenantAlias: TenantAlias) => ({
      mutationFn: (params: { manufacturerID: number }) =>
        getRequestPromise<string>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/manufacturerPriceTemplate`,
          { params },
        ),
    }),
    uploadPartsPricesFileOLD: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: SupplierPriceUploadPartsPricesFilePayload<File>) =>
        uploadRequestPromise<
          void,
          SupplierPriceUploadPartsPricesFilePayload<File>
        >(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/uploadPartsPricesFile`,
          payload,
        ),
    }),
    uploadPartsPricesFile: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: UploadPartsPricesFilePayload<File>) =>
        uploadRequestPromise<void, UploadPartsPricesFilePayload<File>>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/uploadPartsPricesFile`,
          payload,
        ),
    }),
    update: (tenantAlias: TenantAlias, partPriceID: number) => ({
      mutationFn: (payload: SupplierPriceUpdateSupplierPricePayload) =>
        postRequestPromise<void, SupplierPriceUpdateSupplierPricePayload>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/${partPriceID}`,
          payload,
        ),
    }),
    searchOwnParts: (tenantAlias: TenantAlias, params: { search: string }) => ({
      queryKey: ['supplierPrice', 'searchOwnParts', params],
      queryFn: () =>
        getRequestPromise<SupplierPriceSearchOwnPartsResponse>(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/searchOwnParts`,
          { params },
        ),
    }),
    addPriceForPart: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: SupplierPriceAddPriceForPartPayload) =>
        postRequestPromise<
          SupplierPriceAddPriceForPartResponse,
          SupplierPriceAddPriceForPartPayload
        >(
          `${getWebApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/addPriceForPart`,
          payload,
        ),
    }),
    getSupplierPriceDashboard: (
      tenantAlias: TenantAlias,
      params: GetSupplierPriceDashboardPayload,
    ) => ({
      queryKey: ['supplierPrice', 'getSupplierPriceDashboard', params],
      queryFn: () =>
        getRequestPromise<GetSupplierPriceDashboardResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/dashboard`,
          { params },
        ),
    }),
  },
  supplierPriceSet: {
    queryKey: ['supplierPriceSet'],
    list: (tenantAlias: TenantAlias) => ({
      queryKey: ['supplierPriceSet', 'list'],
      queryFn: () =>
        getRequestPromise<ListSupplierPriceSetsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPriceSet`,
        ),
    }),
  },
  user: {
    queryKey: ['user'],
    getCurrentUserInfo: (tenantAlias: TenantAlias) => ({
      queryKey: ['user', 'info'],
      queryFn: () => {
        return getRequestPromise<CurrentUserInfoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/info`,
        );
      },
    }),
    getProfileImage: (
      tenantAlias: TenantAlias,
      userID: number | undefined,
    ) => ({
      queryKey: ['user', 'getProfileImage', userID],
      queryFn: () =>
        getRequestPromise<GetProfileImageResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/profileImage`,
        ).then((data) => data.url),
    }),
    disableUser: (tenantAlias: TenantAlias) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, DisableUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/disableUser`,
        ),
    }),
    enableUser: (tenantAlias: TenantAlias) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, EnableUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/enableUser`,
        ),
    }),
    demoteUserFromAdmin: (tenantAlias: TenantAlias) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, DemoteFromAdminPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/demoteFromAdmin`,
        ),
    }),
    promoteUserToAdmin: (tenantAlias: TenantAlias) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, PromoteToAdminPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/promoteToAdmin`,
        ),
    }),
    changePassword: (tenantAlias: TenantAlias) => ({
      mutationFn: (payload: ChangePasswordPayload) =>
        postRequestPromise<void, ChangePasswordPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/changePassword`,
          payload,
        ),
    }),
    uploadProfileImage: (tenantAlias: TenantAlias, userID: number) => ({
      mutationFn: (payload: UserUploadProfileImagePayload) =>
        uploadRequestPromise<void, UserUploadProfileImagePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/uploadProfileImage`,
          payload,
        ),
    }),
    updateUser: (tenantAlias: TenantAlias, userID: number) => ({
      mutationFn: (payload: UserUpdateUserPayload) =>
        postRequestPromise<UserUpdateUserResponse, UserUpdateUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}`,
          payload,
        ),
    }),
    settings: {
      notificationsData: (tenantAlias: TenantAlias) => ({
        queryKey: ['user', 'settings', 'notificationsData'],
        queryFn: () =>
          getRequestPromise<SettingsNotificationsDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/user/settings/notificationsData`,
          ),
      }),
      updateMuteSMS: (tenantAlias: TenantAlias) => ({
        mutationFn: (payload: UpdateMuteSMSRequest) =>
          postRequestPromise<void, UpdateMuteSMSRequest>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/user/settings/updateMuteSMS`,
            payload,
            'Unable to modify notification settings at this time. Please try again later',
          ),
      }),
    },
  },
  userInviteToken: {
    queryKey: ['userInviteToken'],
    findOne: (tenantAlias: TenantAlias, token: string) => ({
      queryKey: ['userInviteToken', 'findOne', token],
      queryFn: () =>
        getRequestPromise<UserInviteTokenFindOneUserInviteTokenResponse>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/userInviteToken/${token}`,
        ),
    }),
  },
  watchObject: {
    queryKey: ['watchObject'],
    setEnabled: (
      objectType: string,
      objectID: number,
      tenantAlias: TenantAlias,
    ) => ({
      mutationFn: (payload: { enabled: boolean }) =>
        postRequestPromise<void, { enabled: boolean }>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/watchObject/${objectType}/${objectID}/setEnabled`,
          payload,
        ),
    }),
  },
};
