import { ReactElement } from 'react';
import { Skeleton } from '@mui/material';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { ReactComponent as LogoAdvantageelectricsupplyWhite } from './_assets/logo-advantageelectricsupply-white.svg';
import { ReactComponent as LogoAdvantageelectricsupply } from './_assets/logo-advantageelectricsupply.svg';
import { ReactComponent as LogoAppliedautomationWhite } from './_assets/logo-appliedautomation-white.svg';
import { ReactComponent as LogoAppliedautomation } from './_assets/logo-appliedautomation.svg';
import { ReactComponent as LogoDemoWhite } from './_assets/logo-demo-white.svg';
import { ReactComponent as LogoDemo } from './_assets/logo-demo.svg';
import { ReactComponent as LogoLakewoodWhite } from './_assets/logo-lakewood-white.svg';
import { ReactComponent as LogoLakewood } from './_assets/logo-lakewood.svg';
import { ReactComponent as LogoMechatronicsolutionsWhite } from './_assets/logo-mechatronicsolutions-white.svg';
import { ReactComponent as LogoMechatronicsolutions } from './_assets/logo-mechatronicsolutions.svg';
import { ReactComponent as LogoPowermaticWhite } from './_assets/logo-powermatic-white.svg';
import { ReactComponent as LogoPowermatic } from './_assets/logo-powermatic.svg';
export type SPAConfig = {
  // Constants
  tenantAlias: TenantAlias | undefined;
  logo: ReactElement;
  logoWhite: ReactElement;
};
const logoStyle = {
  height: '100%',
  width: '100%'
};
export const getSPAConfigFromTenantAlias = (tenantAlias: TenantAlias | undefined): SPAConfig => {
  if (tenantAlias === 'advantageelectricsupply') {
    return {
      tenantAlias,
      logo: <LogoAdvantageelectricsupply style={logoStyle} />,
      logoWhite: <LogoAdvantageelectricsupplyWhite style={logoStyle} />
    };
  } else if (tenantAlias === 'appliedautomation') {
    return {
      tenantAlias,
      logo: <LogoAppliedautomation style={logoStyle} />,
      logoWhite: <LogoAppliedautomationWhite style={logoStyle} />
    };
  } else if (tenantAlias === 'demo') {
    return {
      tenantAlias,
      logo: <LogoDemo style={logoStyle} />,
      logoWhite: <LogoDemoWhite style={logoStyle} />
    };
  } else if (tenantAlias === 'lakewood') {
    return {
      tenantAlias,
      logo: <LogoLakewood style={logoStyle} />,
      logoWhite: <LogoLakewoodWhite style={logoStyle} />
    };
  } else if (tenantAlias === 'mechatronicsolutions') {
    return {
      tenantAlias,
      logo: <LogoMechatronicsolutions style={logoStyle} />,
      logoWhite: <LogoMechatronicsolutionsWhite style={logoStyle} />
    };
  } else if (tenantAlias === 'powermatic') {
    return {
      tenantAlias,
      logo: <LogoPowermatic style={logoStyle} />,
      logoWhite: <LogoPowermaticWhite style={logoStyle} />
    };
  } else {
    // This fallback should be used as loading while tenantAlias is undefined on client
    return {
      tenantAlias: undefined,
      logo: <Skeleton sx={logoStyle} />,
      logoWhite: <Skeleton style={logoStyle} />
    };
  }
};